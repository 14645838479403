<template>
  <div :style="background" class="login">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol md="12" lg="7" xl="5" class="m-auto">
          <CCard class="p-0">
            <CCardBody class="p-0">
              <CContainer style="padding: 25px;">
                <CRow alignVertical="center">
                  <CCol md="12" lg="12">                  
                    <div v-if="resetStringValid">
                      <div v-if="!passwordResetSuccessful">
                        <h1 class="mb-2">{{ $t('login.Enter_new_password') }}</h1>
                        <p class="mt-0 mb-3">{{ $t('login.Minimum_length') }}</p>

                        <CForm @submit.prevent="login">
                          <span><strong>{{ $t('login.Your_new_password') }}</strong></span>
                          <CInput class="mt-2 mb-2" :placeholder="$t('login.Password')" type="password" v-model="resetPasswordData.password1"></CInput>
                          <span><strong>{{ $t('login.Repeat_your_new_password') }}</strong></span>
                          <CInput class="mt-2 mb-0" :placeholder="$t('login.Password')" type="password" v-model="resetPasswordData.password2"></CInput>
                          <CRow>
                            <CCol md="12" lg="12" class="pb-0">
                              <CButton @click="resetPassword()" color="primary" class="w-100" :disabled="!resetPasswordData.password1 || !resetPasswordData.password2">
                                <i class="fas fa-paper-plane mr-2"/>{{ $t('login.Reset_my_password') }}
                              </CButton>
                            </CCol>
                            <CCol v-if="resetPasswordErrorMessage" col="12" class="pb-0 text-center">
                              <p class="m-0">{{ resetPasswordErrorMessage }}</p>                        
                            </CCol>
                          </CRow>
                        </CForm>
                      </div>
                      <div v-else>
                        <CRow>
                          <CCol md="12" lg="12" class="pt-0 pb-0">
                            <p class="mt-0 mb-3">{{ $t('login.Password_reset_successfully') }}</p>
                            <CButton @click="openLoginPage()" color="primary" class="w-100">
                              {{ $t('login.Return_to_login_page') }}<i class="fas fa-chevron-right ml-2"/>
                            </CButton>                            
                          </CCol>
                        </CRow>                        
                      </div>                    
                    </div>
                    <div v-else>
                      <CRow>
                        <CCol md="12" lg="12" class="pt-0 pb-0">
                          <h1 class="mb-3">{{ $t('login.Reset_link_unavailable') }}</h1>
                          <p class="mt-0 mb-3">{{ $t('login.Password_reset_link_unavailable') }}</p>
                          <CButton @click="openLoginPage()" color="primary" class="w-100">
                            {{ $t('login.Return_to_login_page') }}<i class="fas fa-chevron-right ml-2"/>
                          </CButton>                            
                        </CCol>
                      </CRow>                        
                    </div>
                  </CCol>
                </CRow>
              </CContainer>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="logo_placeholder">
      <div v-if="environmentVariables.company_id_external && environmentVariables.company_image_id">
        <img v-if="cdnBaseUrl" :src="cdnBaseUrl + '/base/dashboard/' + environmentVariables.company_id_external + '/' + environmentVariables.company_image_id + '.png'"/>
      </div>
      <div v-else-if="environmentVariables.environment_id_external">
        <img :src="getLogoSrc(environmentVariables.environment_tag)"/>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data(){
    return {
      copyrightText: null,    
      background : {
        backgroundImage: "linear-gradient(45deg, rgba(0, 104, 89, 0.9), rgba(48, 146, 139, 0.95)), url('./img/login/background-pattern.png')",
        backgroundRepeat: 'repeat',
      },
      resetString: null,
      resetStringValid: false,
      passwordResetSuccessful: false,
      resetPasswordErrorMessage: null,
      resetPasswordData: {
        password1: null,
        password2: null
      },
      environmentVariables: {
        company_id_external: null,
        environment_id_external: null
      }      
    }
  },
  methods: {
    getLogoSrc(environmentTag) {
      return `${this.cdnBaseUrl}/base/dashboard/logo/${environmentTag}_logo.png`;
    },    
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the background image
        this.background.backgroundImage =  "linear-gradient(45deg, rgba(" + this.environmentVariables.primary_color_rgb + ", 0.85), rgba(" + this.environmentVariables.primary_color_rgb + ", 0.9)), url('./img/login/background-pattern.png')",
        // Set the copyright text
        this.copyrightText = this.environmentVariables.copyright;        
        // Set the favicon href
        document.querySelector("link[rel~='icon']").href = `${this.cdnBaseUrl}/base/dashboard/logo/${this.environmentVariables.environment_tag}_favicon.png`;
        // Set the placeholder for the email field
        this.emailFieldPlaceholder = "📩 " + this.environmentVariables.environment_contact_email;        
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    checkResetString() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/resetpassword/' + this.resetString)
      .then(res => {
        if(res.data.code === 'Reset link valid') this.resetStringValid = true;
      })
      .catch(err => {
        if(err.response.data.code === 'Reset link invalid') this.resetStringValid = false;
      });
    },
    resetPassword() {
      let params = {};
      // Set the params        
      params.resetPasswordData = this.resetPasswordData;
      // Reset the resetPasswordErrorMessage value
      this.resetPasswordErrorMessage = null;
      // Check the provided email address
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/resetpassword/' + this.resetString, params)
      .then(res => {
        if(res.data.code === 'Password reset succesful') this.passwordResetSuccessful = true;
      })
      .catch(err => {;
        if(err.response.data.code === 'No matching passwords') {
          this.resetPasswordErrorMessage = this.$t('login.Passwords_do_not_match');
        } else if(err.response.data.code === 'Invalid password length') {
          this.resetPasswordErrorMessage = this.$t('login.Use_longer_password');
        }
      });        
    },    
    openLoginPage() {
      this.$router.push({path: '/login'});
    }    
  },
  mounted: function() {
    // Get the environment variables
    this.getEnvironmentVariables();

    if(this.$route.params.id) {
      // Set the resetString value
      this.resetString = this.$route.params.id;
      // Check the reset string
      this.checkResetString();     
    }  
  }
}
</script>
